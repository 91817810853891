export const mixinCustomTableEmits = {
  methods: {
    onAddRow () {
      this.$emit('add-row')
    },
    onAddColumn ({ head, columns }) {
      this.$emit('add-column', { head, columns })
    },
    onTh ({ row, rowIdx, col, colIdx, head, columns }) {
      this.$emit('change-th', { row, rowIdx, col, colIdx, head, columns })
    },
    onRemoveColumn ({ row, rowIdx, col, colIdx, head, columns }) {
      this.$emit('remove-column', { row, rowIdx, col, colIdx, head, columns })
    },
    onTd ({ dataRowIdx, col, value, idx, data, columns }) {
      this.$emit('change-td', { dataRowIdx, col, value, idx, data, columns })
    },
    onRemoveRow ({ data, idx }) {
      this.$emit('remove-row', { data, idx })
    },
    onCancel () {
      this.$emit('cancel-changes')
    }
  }
}
