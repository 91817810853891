import { timingColEditKey, ETableTypes } from './const'
import { addCustomEdit } from '@/shared/ui/CustomTable/utils'
import { getNameOrDash } from '@/shared/lib/utils/map/handbook'
import { mapNameJoinSlash } from '@/shared/lib/utils/map/to-string'

export function getGroupBySpringType (id) {
  switch (id) {
    case 1:
    case 6: return 1
    case 2:
    case 7:
    case 9: return 2
    case 3:
    case 4:
    case 8: return 3
    default: return 4
  }
}

export function isWaitingForLaunchTable (typeId) {
  return typeId === ETableTypes.waitingForLaunch
}

export function isCurrentLoadTable (typeId) {
  return typeId === ETableTypes.currentLoad
}

export function addTimingColumnKeys () {
  return {
    ...addCustomEdit(timingColEditKey),
    mapFn: mapTimingTd
  }
}

export function mapTimingTd (item) {
  return getNameOrDash(item?.timing)
}

export function mapGOSTTh (value) {
  const text = mapNameJoinSlash(value)
  return text ? `${text} (i)` : ''
}

export function getGOSTTooltipPopupText (col) {
  const set = new Set()

  for (const gost of col.value) {
    if (!gost.materials) continue
    for (const material of gost.materials) {
      set.add(material.name)
    }
  }

  return Array.from(set).join(' / ')
}
