<template>
  <div class="custom-table column gap-s" :class="isEdit && 'is-edit'">
    <div v-if="visibleEdit || title" class="between-center gap-m">
      <slot name="title">
        <h3 v-if="title">{{ title }}</h3>
      </slot>

      <div v-if="visibleEdit" class="d-flex gap-xs ml-auto">
        <VButton
          v-if="hasButtonAddColumn && isEdit"
          text="Добавить колонку"
          icon="create"
          size="sm"
          color="primary-sm"
          @click.native="addColumn"
        />

        <VButton
          v-if="hasButtonRemoveTable && isEdit"
          text="Удалить таблицу"
          icon="remove"
          size="sm"
          @click.native="openModal('remove')"
        />

        <VButton
          text="Редактировать"
          :class="isEdit && 'disabled filter-gray'"
          size="sm"
          icon="edit"
          @click.native="toggleEdit"
        />
      </div>
    </div>

    <div class="custom-table__wrapper custom-scroll">
      <table class="custom-table__table">
        <slot name="head">
          <CustomTableHead
            :head="headCopy"
            :is-edit="isEdit"
            @change-th="changeTh"
            @remove-column="removeColumn"
          />
        </slot>

        <tbody class="custom-table__body table__body">
          <CustomTableRow
            v-for="(data, idx) of rowsCopy"
            :key="data.id"
            :columns="columnsCopy"
            :data="data"
            :is-edit="isEdit"
            :has-button-remove-row="hasButtonRemoveRow"
            @change-td="
              ({ rowIdx, col, value }) =>
                checkColTd({ dataRowIdx: rowIdx, col, value, data, idx })
            "
            @remove-row="removeRow({ data, idx })"
          >
            <template #before="slotProps">
              <slot
                name="row-before"
                :data="data"
                :idx="idx"
                :rowIdx="slotProps.rowIdx"
                :isEdit="isEdit"
              />
            </template>

            <template #after="slotProps">
              <slot
                name="row-after"
                :data="data"
                :idx="idx"
                :rowIdx="slotProps.rowIdx"
                :isEdit="isEdit"
              />
            </template>
          </CustomTableRow>  
          <slot name="sum"/>
        </tbody>
      </table>
    </div>

    <VButton
      v-if="hasButtonAddRow && isEdit"
      text="Добавить строку"
      icon="create"
      size="sm"
      color="primary-sm"
      @click.native="addRow"
    />

    <ButtonGroup
      v-if="isEdit"
      textBtnConfirm="Сохранить"
      textBtnReject="Отмена"
      btn-size="l"
      :loading="isLoading"
      @confirm="saveChanges"
      @reject="cancelChanges"
    />

    <ModalFormSimple
      v-if="isModal === 'form'"
      :init-data="modal.formData"
      :input-label="modal.inputLabel"
      @request="saveModalChanges"
      @close="closeModalForm"
    />

    <ModalConfirmation
      v-if="isModal === 'remove'"
      @confirm="removeTable"
      @reject="closeModal"
    />

    <slot name="modals" />
  </div>
</template>

<script>
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup.vue'
import CustomTableHead from '@/shared/ui/CustomTable/CustomTableHead.vue'
import CustomTableRow from '@/shared/ui/CustomTable/CustomTableRow.vue'
import VButton from '@/components/simple/button/VButton.vue'

import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import { mixinCustomTableProps } from '@/shared/ui/CustomTable/mixinCustomTableProps'
import { mixinCustomTableEmits } from '@/shared/ui/CustomTable/mixinCustomTableEmits'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'

import { showAlertError } from '@/utils/store/alert'
import { deepClone } from '@/utils/deepClone'
import { mixinModal } from '@/mixins/modal/mixinModal'
import ModalFormSimple from '@/shared/ui/form/ModalFormSimple.vue'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'

export default {
  name: 'CustomTable',
  components: {
    ModalConfirmation,
    ModalFormSimple,
    ButtonGroup,
    VButton,
    CustomTableRow,
    CustomTableHead
  },

  mixins: [
    mixinCustomTableProps,
    mixinCustomTableEmits,
    mixinIsEdit,
    mixinModal,
    mixinIsLoading
  ],

  data: () => ({
    headCopy: [],
    columnsCopy: [],
    rowsCopy: [],
    modal: {
      isModal: false,
      payload: null,
      formData: {
        value: ''
      },
      inputLabel: ''
    }
  }),

  created () {
    this.syncData()
  },

  methods: {
    async saveChanges () {
      try {
        this.startLoading()

        await this.updateHandler({
          head: this.headCopy,
          columns: this.columnsCopy,
          rows: this.rowsCopy
        })

        this.turnOffIsEdit()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    cancelChanges () {
      this.turnOffIsEdit()
      this.syncData()
      this.onCancel()
    },

    saveModalChanges (formData) {
      const { payload } = this.modal
      const key = payload.col.key
      const idx = payload.dataRowIdx
      payload.data.rows[idx][key] = formData.value

      this.closeModalForm()
    },

    checkColTd (payload) {
      if (payload.col.isCustomEdit) {
        return this.onTd({ ...payload, columns: this.columnsCopy })
      }

      this.openModalForm(payload)
    },

    openModalForm (payload) {
      this.modal.inputLabel = payload.col.label
      this.modal.formData.value = payload.value
      this.modal.payload = payload

      this.openModal('form')
    },

    closeModalForm () {
      this.closeModal()
      this.resetModalState()
    },

    resetModalState () {
      this.modal.inputLabel = ''
      this.modal.formData.value = ''
      this.modal.payload = null
    },

    syncData () {
      this.syncHeadCopy()
      this.syncColumnsCopy()
      this.syncRowsCopy()
    },

    syncHeadCopy () {
      this.headCopy = []

      for (const row of this.head) {
        const copy = []

        for (const item of row) {
          copy.push(deepClone(item))
        }

        this.headCopy.push(copy)
      }
    },

    syncColumnsCopy () {
      this.columnsCopy = []

      for (const col of this.columns) {
        this.columnsCopy.push(deepClone(col))
      }
    },

    syncRowsCopy () {
      this.rowsCopy = []

      for (const row of this.rows) {
        this.rowsCopy.push(deepClone(row))
      }
    },

    addColumn () {
      this.onAddColumn({
        head: this.headCopy,
        columns: this.columnsCopy
      })
    },

    changeTh (ctx) {
      this.onTh({
        ...ctx,
        head: this.headCopy,
        columns: this.columnsCopy
      })
    },

    removeColumn (ctx) {
      this.onRemoveColumn({
        ...ctx,
        head: this.headCopy,
        columns: this.columnsCopy
      })
    },

    addRow () {
      const row = this.getNewRow(this.columnsCopy)
      this.rowsCopy.push(row)
    },

    removeRow ({ idx }) {
      this.rowsCopy.splice(idx, 1)
    },

    async removeTable () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        await this.removeHandler()
        this.closeModal()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
