import { toHandbook } from '@/shared/lib/utils/map/handbook'

export const TABLE_COLSPAN = [
  'Один',
  'Два'
].map(toHandbook)

export const CUSTOM_TABLE_CLASSES = {
  borderRightBold: 'table-border-right-bold',
  borderBottomBold: 'table-border-bottom-bold'
}
