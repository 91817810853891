<template>
  <thead class="custom-table__head table__head">
    <tr v-for="(row, rowIdx) of head" :key="rowIdx">
      <th
        v-for="(col, colIdx) of row"
        :key="colIdx"
        class='custom-table__th'
        :class="[
            col.classes,
            rowIdx > 0 && 'th--border-t',
            col.isEdit && 'is-hover',
            col.mapFnClasses && col.mapFnClasses(col, colIdx, row, true)
          ]"
        :rowspan="col.rowspan"
        :colspan="col.colspan"
        @click='isEdit && col.isEdit && onTh({ row, rowIdx, col, colIdx })'
      >
        <VTooltip
          v-if="col.tooltip"
          :text="getText(col)"
          :max-length="col.tooltip.maxLength || 20"
          :popup-text="col.tooltip.getPopupText ? col.tooltip.getPopupText(col) : col.tooltip.popupText || null"
          :show-tip="!!col.tooltip.getPopupText || !!col.tooltip.popupText"
        />

        <span v-else>
          {{ getText(col) }}
        </span>

        <button
          v-if='isEdit && col.hasButtonRemove && col.isEdit'
          class='custom-table__th-button-remove btn-cross btn-cross--remove btn-reset'
          @click.stop='onRemove({ row, rowIdx, col, colIdx })'
        />
      </th>
    </tr>
  </thead>
</template>

<script>
import VTooltip from '@/components/ui/VTooltip.vue'

export default {
  name: 'CustomTableHead',
  components: { VTooltip },
  props: {
    head: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTh ({ row, rowIdx, col, colIdx }) {
      this.$emit('change-th', { row, rowIdx, col, colIdx })
    },
    onRemove ({ row, rowIdx, col, colIdx }) {
      this.$emit('remove-column', { row, rowIdx, col, colIdx })
    },

    getText (col) {
      return col.mapFn ? col.mapFn(col.value) : col.text
    }
  }
}
</script>
