<template>
  <VForm @request="request">
    <div class="form-grid--col-2 input__title">
      <label>от</label>

      <label>до</label>
    </div>

    <div class="form-grid--col-2 mb-30">
      <div v-for="(item, idx) of formData.ranges" :key="idx" class="d-contents">
        <VInput
          v-model="item.from"
          :input-type="inputType"
          :pattern="pattern"
        >
          <Validation for="pattern" key-text="num100" />
        </VInput>

        <InputClear
          v-model="item.to"
          :input-type="inputType"
          :pattern="pattern"
          :has-button-remove="idx !== 0"
          is-emit-remove
          @on-remove="removeRange(idx)"
        >
          <Validation v-if="patternKey" for="pattern" :key-text="patternKey" />
        </InputClear>
      </div>
    </div>

    <VButton
      class="mb-30"
      text="Добавить диапазон"
      icon="create"
      size="sm"
      color="primary-sm"
      @click.native="addRange"
    />

    <ButtonGroup
      textBtnConfirm="Сохранить"
      textBtnReject="Отмена"
      @reject="onReject"
    />
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import InputClear from '@/components/Form/Vinput/InputClear.vue'
import VButton from '@/components/simple/button/VButton.vue'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup.vue'
import Validation from '@/components/Form/Validation.vue'
import { mixinFormData } from '@/mixins/form/mixinFormData'
import { PATTERN_LIST, patternKeyValidator } from '@/const/validation'
import { getRangeItem } from './utils'

export default {
  name: 'FormRange',
  components: { Validation, ButtonGroup, VButton, InputClear, VInput, VForm },
  mixins: [mixinFormData],
  props: {
    inputType: {
      type: String
    },
    patternKey: {
      type: String,
      validator: patternKeyValidator
    }
  },
  computed: {
    pattern () {
      if (!this.patternKey) return null

      return PATTERN_LIST[this.patternKey]
    }
  },
  methods: {
    request () {
      this.$emit('request', this.formData)
    },

    onReject () {
      this.$emit('reject')
    },

    removeRange (idx) {
      this.formData.ranges.splice(idx, 1)
    },
    addRange () {
      this.formData.ranges.push(getRangeItem())
    }
  }
}
</script>
