<template>
  <div class='custom-table__row'>
    <tr
      v-for='(row, rowIdx) of data.rows'
      :key='rowIdx'
      :class="rowIdx === 0 && 'relative'"
    >
      <slot name='before' :rowIdx='rowIdx' />

      <td
        v-for='(col, colIdx) of columns'
        :key='col.key + colIdx'
        class='custom-table__td'
        :class="[
          col.isEdit && 'is-hover',
          `custom-table__td--${colIdx + 1}`,
          col.mapFnClasses && col.mapFnClasses(col, colIdx, columns)
        ]"
        @click="col.isEdit && isEdit && onTd({ rowIdx, col, value: row[col.key] })"
      >
        {{ col.mapFn ? col.mapFn(row[col.key]) : row[col.key] }}
      </td>

      <slot name='after' :rowIdx='rowIdx' />

      <button
        v-if='hasButtonRemoveRow && rowIdx === 0 && isEdit'
        class='custom-table__row-button-remove btn-cross btn-cross--remove btn-reset'
        @click='onRemoveRow'
      />
    </tr>
  </div>
</template>

<script>
export default {
  name: 'CustomTableRow',
  props: {
    data: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    hasButtonRemoveRow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onRemoveRow () {
      this.$emit('remove-row')
    },
    onTd ({ rowIdx, col, value }) {
      this.$emit('change-td', { rowIdx, col, value })
    }
  }
}
</script>
