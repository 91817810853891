<template>
  <span>
    Обновлено: {{ updatedAt }}
  </span>
</template>

<script>
import { formatDateTime } from '@/utils/format/date'

export default {
  name: 'UpdatedAt',
  props: {
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    updatedAt () {
      return formatDateTime(this.date)
    }
  }
}
</script>
