import { mockRequest } from '@/mocks/mockRequest'

export const mixinCustomTableProps = {
  props: {
    title: {
      type: String
    },
    head: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    hasButtonRemoveTable: {
      type: Boolean
    },
    hasButtonAddColumn: {
      type: Boolean,
      default: true
    },
    hasButtonAddRow: {
      type: Boolean,
      default: true
    },
    hasButtonRemoveRow: {
      type: Boolean,
      default: true
    },
    visibleEdit: {
      type: Boolean,
      default: false
    },
    updateHandler: {
      type: Function,
      default: mockRequest
    },
    removeHandler: {
      type: Function,
      default: () => {}
    },
    getNewRow: {
      type: Function,
      default: () => {}
    }
  }
}
