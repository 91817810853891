<template>
  <LoadCalendarTable
    ref="LoadCalendarTable"
    class="calendar-current-load-table"
    title="Текущая загрузка"
    :head="head"
    :columns="columns"
    :rows="rows"
    :visible-edit="visibleEdit"
    has-button-remove-table
    :has-button-add-column="false"
    :get-row-child="getRowChild"
    :remove-handler="removeHandler"
    :update-handler="updateHandler"
  >
    <template #title>
      <div class="column gap-xxs">
        <h3>Текущая загрузка</h3>

        <UpdatedAt v-if="updatedAt" :date="updatedAt" />
      </div>
    </template>

    <template #sum>
        <div 
            v-if="sum"
            class="custom-table__row"
        >
            <tr>
                <td class="custom-table__td custom-table__td--1">
                    <b>Итого:</b>
                </td>
                <td class="custom-table__td custom-table__td--2"></td>
                <td class="custom-table__td custom-table__td--3"></td>
                <td class="custom-table__td custom-table__td--4">
                    {{sum.position_amount}}
                </td>
                <td class="custom-table__td custom-table__td--5">
                    {{sum.spring_amount}}
                </td>
            </tr>
        </div>
    </template>
  </LoadCalendarTable>
</template>

<script>
import LoadCalendarTable from './LoadCalendarTable.vue'
import UpdatedAt from '@/shared/ui/UpdatedAt/UpdatedAt.vue'

import {
  tableHead,
  rangeCol,
  positionAmountTH,
  amountTH
} from '../../model/const'
import { formatNum } from '@/utils/format/number'

export default {
  name: 'CurrentLoadTable',
  components: { UpdatedAt, LoadCalendarTable },

  props: {
    rows: {
      type: Array,
      required: true
    },
    sum: {
        type: Object,
        required: false
    },
    updatedAt: {
      type: String
    },
    updateHandler: {
      type: Function
    },
    removeHandler: {
      type: Function
    },
    visibleEdit: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    head: [[...tableHead, positionAmountTH, amountTH]],
    columns: [
      rangeCol,
      {
        label: positionAmountTH.text,
        key: 'position_amount',
        mapFn: formatNum
      },
      {
        label: amountTH.text,
        key: 'spring_amount',
        mapFn: formatNum
      }
    ]
  }),

  methods: {
    getRowChild () {
      return {
        position_amount: 0,
        spring_amount: 0
      }
    },

    syncData () {
      this.$refs.LoadCalendarTable.$refs.CustomTable.syncData()
    }
  }
}
</script>

<style lang="scss">
.calendar-current-load-table {
  table {
    width: initial;
  }

  .custom-table__th {
    border-bottom: var(--border-bold) solid var(--color__cyan);
  }

  .custom-table {
    &__row:last-child {
      tr:first-child {
        td:first-child {
          border-bottom-left-radius: inherit;
        }
      }
    }
  }
}
</style>
