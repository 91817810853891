import { formatNum } from '@/utils/format/number'

export function mapAmountRange ({ from, to }) {
  const fromFormatted = from && from !== 0 ? formatNum(from) : '<'
  const toFormatted = to ? formatNum(to) : '>'

  return mapRange({ from: fromFormatted, to: toFormatted })
}

export function mapRange ({ from, to }) {
  return `${from} - ${to}`
}

export function mapNameJoinSlash (values) {
  return values.map(item => item.name).join(' / ')
}
