import { toHandbook } from '@/shared/lib/utils/map/handbook'

export const TABLE_TYPES = [
  'Текущая загрузка',
  'В ожидании запуска',
  'По операциям',
  'По ГОСТу',
  'Заказы из наличия'
].map(toHandbook)

export const ETableTypes = {
  currentLoad: 1,
  waitingForLaunch: 2,
  byOperation: 3,
  byGOST: 4,
  byCoatingType: 5
}
