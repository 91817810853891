import {
  mapAmountRange
} from '@/shared/lib/utils/map/to-string'
import { getGOSTTooltipPopupText } from '../utils'

export * from './table-types'
export * from './table-operations'
export * from './restruction-warehouse'

const windingMethodTH = { text: 'Способ навивки' }
const dWireTH = { text: 'd пров.' }
export const rangeTH = { text: 'Кол-во шт. в позиции' }

export const tableHead = [
  windingMethodTH,
  dWireTH,
  rangeTH
]

export const waitingForLaunchHead = ['Новые в очереди', 'Очередь на запуск', 'Очередь в работу']
  .map(text => ({ text, colspan: 2 }))

export const waitingForLaunchColumnKeys = [
  'launch_positions_amount',
  'launch_springs_amount',
  'workshop_positions_amount',
  'workshop_springs_amount',
  'winding_positions_amount',
  'winding_springs_amount'
]

export const positionAmountTH = { text: 'Кол-во позиций' }
export const amountTH = { text: 'Кол-во шт.' }

export const rangeCol = {
  key: 'range',
  isEdit: true,
  isCustomEdit: true,
  mapFn: mapAmountRange
}

export const operationsThEditKey = 'is-operations'
export const GOSTThEditKey = 'is-gost'
export const timingColEditKey = 'is-timing'

export const GOSTThTooltip = {
  maxLength: 500,
  getPopupText: getGOSTTooltipPopupText
}
