import { toHandbook } from '@/shared/lib/utils/map/handbook'

export const LOAD_CALENDAR_TABLE_OPERATIONS = [
  'Не шлиф.',
  'Шлиф.',
  'Без зацепа',
  'С зацепом',
  'Одинарная',
  'Двойная'
].map(toHandbook)
