<template>
  <LoadCalendarTable
    ref="LoadCalendarTable"
    class="waiting-for-launch-table"
    :head="head"
    :columns="columns"
    :rows="rows"
    :visible-edit="visibleEdit"
    hide-winding-methods-col
    hide-d-wires-col
    :has-button-add-row="false"
    :has-button-add-column="false"
    :has-button-remove-row="false"
    :get-row-child="getRowChild"
    :update-handler="updateHandler"
  >
    <template #title>
      <div class="column gap-xxs">
        <h3>{{ title }}</h3>

        <UpdatedAt v-if="updatedAt" :date="updatedAt" />
      </div>
    </template>

    <template #sum>
        <div 
            v-if="sum"
            class="custom-table__row"
        >
            <tr>
                <td class="custom-table__td custom-table__td--1">
                    <b>Итого:</b>
                </td>
                <td class="custom-table__td custom-table__td--2">
                    {{ sum.launch_positions_amount }}
                </td>
                <td class="custom-table__td custom-table__td--3">
                    {{sum.launch_springs_amount}}
                </td>
                <td class="custom-table__td custom-table__td--4">
                    {{sum.workshop_positions_amount}}
                </td>
                <td class="custom-table__td custom-table__td--5">
                    {{sum.workshop_springs_amount}}
                </td>
                <td class="custom-table__td custom-table__td--6">
                    {{sum.winding_positions_amount}}
                </td>
                <td class="custom-table__td custom-table__td--7">
                    {{sum.winding_springs_amount}}
                </td>
            </tr>
        </div>
    </template>
  </LoadCalendarTable>
</template>

<script>
import UpdatedAt from '@/shared/ui/UpdatedAt/UpdatedAt.vue'
import LoadCalendarTable from './LoadCalendarTable.vue'
import {
  rangeCol,
  positionAmountTH,
  amountTH,
  rangeTH,
  waitingForLaunchHead,
  waitingForLaunchColumnKeys
} from '../../model/const'
import { formatNum } from '@/utils/format/number'

export default {
  name: 'WaitingForLaunchTable',
  components: {
    UpdatedAt,
    LoadCalendarTable
  },

  props: {
    rows: {
      type: Array,
      required: true
    },
    sum: {
        type: Object,
        required: false
    },
    updatedAt: {
      type: String
    },
    ordersAmount: {
      type: Number
    },
    updateHandler: {
      type: Function
    },
    visibleEdit: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    head: [
      [
        {
          ...rangeTH,
          rowspan: 2
        },
        ...waitingForLaunchHead
      ],
      [
        positionAmountTH,
        amountTH,
        positionAmountTH,
        amountTH,
        positionAmountTH,
        amountTH
      ]
    ],

    columns: [
      rangeCol,
      ...waitingForLaunchColumnKeys.map(key => ({ key, mapFn: formatNum }))
    ]
  }),

  computed: {
    title () {
      return `Кол-во заказов в ожидании на запуск: ${this.ordersAmount || 0}`
    }
  },

  methods: {
    getRowChild () {
      const row = {}

      waitingForLaunchColumnKeys.forEach(key => {
        row[key] = 0
      })

      return row
    },
    syncData () {
      this.$refs.LoadCalendarTable.$refs.CustomTable.syncData()
    }
  }
}
</script>

<style lang="scss">
.waiting-for-launch-table {
  .custom-table__head {
    tr:first-child {
      .custom-table__th:not(:last-child) {
        border-right: var(--border-bold) solid var(--color__cyan);
      }
    }

    tr:nth-child(2) {
      .custom-table__th:nth-child(2),
      .custom-table__th:nth-child(4) {
        border-right: var(--border-bold) solid var(--color__cyan);
      }
    }
  }

  .custom-table__body {
    .custom-table__td:nth-child(odd):not(:last-child) {
      border-right: var(--border-bold) solid var(--color__cyan);
    }
  }
}
</style>
