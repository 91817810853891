<template>
  <LoadCalendarTable
    class="production-time-table"
    :class="isTableBySpringGroup && 'production-time-table--by-group'"
    :head="head"
    :columns="columns"
    :rows="rows"
    :visible-edit="visibleEdit"
    has-button-remove-table
    :update-handler="updateHandler"
    :remove-handler="removeHandler"
    :get-row-child="getRowChild"
    @add-column="openModalCreateColumn"
    @change-th="checkChangeTh"
    @change-td="openModalTiming"
    @remove-column="removeColumn"
  >
    <template #title>
      <div v-if="visibleTitle || updatedAt" class="column gap-xxs">
        <h3 v-if="visibleTitle" >Прогнозируемые сроки производства</h3>

        <UpdatedAt v-if="updatedAt" :date="updatedAt" />
      </div>
    </template>

    <template #modals>
      <ModalForm
        v-if="isModal === modalSpringTypes.name"
        v-model="modalSpringTypes.formData"
        title="Редактировать"
        @request="switchModalSpringTypesAction"
        @reject="closeModalSpringTypes"
      >
        <VSelect
          label="Тип пружины"
          class="mb-20"
          name="springTypes"
          multiple
          :options="springTypeOptionsFiltered"
          required
        >
          <Validation for="required" />
        </VSelect>

        <VSelect
          label="Количество столбцов"
          class="mb-30"
          name="colspan"
          :options="colspanOptions"
          :allow-empty="false"
        />
      </ModalForm>

      <ModalForm
        v-if="isModal === modalCoatingType.name"
        v-model="modalCoatingType.formData"
        title="Редактировать"
        @request="switchModalCoatingTypeAction"
        @reject="closeModalCoatingType"
      >
        <VSelect
          label="Тип покрытия"
          class="mb-30"
          name="coating"
          :options="$store.state.records.properties.coating"
          required
        >
          <Validation for="required" />
        </VSelect>
      </ModalForm>

      <ModalForm
        v-if="isModal === modalOperation.name"
        v-model="modalOperation.formData"
        title="Редактировать"
        @request="saveOperation"
        @reject="closeModalOperation"
      >
        <VSelect
          label="Операция"
          class="mb-30"
          name="operation"
          :options="operationOptions"
          required
        >
          <Validation for="required" />
        </VSelect>
      </ModalForm>

      <ModalForm
        v-if="isModal === modalGOST.name"
        v-model="modalGOST.formData"
        title="Редактировать"
        @request="saveGOST"
        @reject="closeModalGOST"
      >
        <VSelect
          label="ГОСТ"
          class="mb-30"
          name="gosts"
          multiple
          :options="GOSTOptions"
          required
        >
          <Validation for="required" />
        </VSelect>
      </ModalForm>

      <ModalForm
        v-if="isModal === modalTiming.name"
        v-model="modalTiming.formData"
        title="Редактировать"
        @request="saveTiming"
        @reject="closeModalTiming"
      >
        <VSelect
          label="Срок"
          class="mb-30"
          name="timing"
          :options="$store.getters['records/timingForLoadCalendarTable']"
        />
      </ModalForm>
    </template>
  </LoadCalendarTable>
</template>

<script>
import LoadCalendarTable from './LoadCalendarTable.vue'
import VSelect from '@/components/Form/VSelect.vue'
import ModalForm from '@/components/ui/Modal/ModalForm.vue'
import Validation from '@/components/Form/Validation.vue'
import UpdatedAt from '@/shared/ui/UpdatedAt/UpdatedAt.vue'

import { mixinModal } from '@/mixins/modal/mixinModal'

import { mapGetters, mapState } from 'vuex'
import {
  GOSTThEditKey,
  rangeCol,
  ETableTypes,
  LOAD_CALENDAR_TABLE_OPERATIONS,
  operationsThEditKey, GOSTThTooltip
} from '../../model/const'
import { addTimingColumnKeys, getGroupBySpringType, mapGOSTTh } from '../../model/utils'
import { TABLE_COLSPAN } from '@/shared/ui/CustomTable/const'
import { findById, findByKeyValue } from '@/utils/array/find'
import { showAlertWarning } from '@/utils/store/alert'
import { getNameOrDash } from '@/shared/lib/utils/map/handbook'
import { addCustomEdit, setBorderRightClassByGroupId } from '@/shared/ui/CustomTable/utils'
import { mapNameJoinSlash } from '@/shared/lib/utils/map/to-string'

export default {
  name: 'ProductionTimeTable',
  components: {
    UpdatedAt,
    Validation,
    ModalForm,
    VSelect,
    LoadCalendarTable
  },
  mixins: [mixinModal],
  props: {
    head: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    updatedAt: {
      type: String
    },
    updateHandler: {
      type: Function
    },
    removeHandler: {
      type: Function
    },
    tableType: {
      type: Number,
      default: ETableTypes.byOperation,
      validator: value => {
        switch (value) {
          case ETableTypes.byOperation:
          case ETableTypes.byGOST:
          case ETableTypes.byCoatingType:
            return true
        }

        return false
      }
    },
    visibleEdit: {
      type: Boolean,
      default: false
    },
    visibleTitle: {
      type: Boolean
    }
  },

  data: () => ({
    ctx: null,

    modalSpringTypes: {
      name: 'spring-types',
      formData: {
        springTypes: [],
        colspan: TABLE_COLSPAN[1]
      },
      isCreate: true
    },

    modalCoatingType: {
      name: 'coating-type',
      formData: {
        coating: null
      },
      isCreate: true
    },

    modalGOST: {
      name: 'GOST',
      formData: {
        materials: []
      }
    },

    modalOperation: {
      name: 'operation',
      formData: {
        operation: null
      }
    },

    modalTiming: {
      name: 'timing',
      formData: {
        timing: null
      }
    }
  }),

  computed: {
    ...mapState('handbook/gost', {
      GOSTOptions: 'list'
    }),
    ...mapGetters('permission', {
      isAdmin: 'isAdmin'
    }),

    isTableBySpringGroup () {
      return this.isTableByGOST || this.tableType === ETableTypes.byOperation
    },

    isTableByGOST () {
      return this.tableType === ETableTypes.byGOST
    },

    springTypeOptionsFiltered () {
      const { springTypes } = this.modalSpringTypes.formData

      if (!springTypes?.length) return this.springTypeOptions

      return this.getSpringTypeOptionsById(springTypes[0].id)
    },

    getSpringTypeOptionsById () {
      return id => {
        switch (id) {
          case 1:
          case 6:
            return this.springTypeOptions16
          case 2:
          case 7:
          case 9:
            return this.springTypeOptions279
          case 3:
          case 4:
          case 8:
            return this.springTypeOptions348
          case 5:
            return this.springTypeOptions5
        }

        return []
      }
    },

    springTypeOptions16 () {
      return this.$store.state.records.properties.product_type.filter(item =>
        [1, 6].includes(item.id)
      )
    },
    springTypeOptions279 () {
      return this.$store.state.records.properties.product_type.filter(item =>
        [2, 7, 9].includes(item.id)
      )
    },
    springTypeOptions348 () {
      return this.$store.state.records.properties.product_type.filter(item =>
        [3, 4, 8].includes(item.id)
      )
    },
    springTypeOptions5 () {
      return this.$store.state.records.properties.product_type.filter(
        item => item.id === 5
      )
    },
    springTypeOptions () {
      return this.$store.state.records.properties.product_type
    },

    colspanOptions () {
      return TABLE_COLSPAN
    },

    operationOptions () {
      return LOAD_CALENDAR_TABLE_OPERATIONS
    }
  },

  methods: {
    switchModalSpringTypesAction (formData) {
      if (this.modalSpringTypes.isCreate) {
        return this.createHeadSpringTypes(formData)
      }

      this.updateHeadSpringTypes(formData)
    },

    createHeadSpringTypes (formData) {
      try {
        const groupId = getGroupBySpringType(formData.springTypes[0].id)
        this.checkHasGroup(groupId)

        const colspan = formData.colspan.id

        this.ctx.head[0].push({
          value: [...formData.springTypes],
          colspan,
          groupId,
          isEdit: true,
          isCustomEdit: true,
          mapFn: mapNameJoinSlash,
          hasButtonRemove: true
        })

        if (!this.ctx.head[1]) {
          this.ctx.head.push([])
        }

        let head
        if (this.isTableByGOST) {
          head = this.getHeadForGOST({ groupId, colspan })
        } else {
          head = this.getHeadForOperations({ groupId, colspan })
        }

        this.ctx.head[1].push(...head)

        const columns = this.getColumnsForTiming({ groupId, colspan })
        this.ctx.columns.push(...columns)

        this.closeModalSpringTypes()
      } catch (e) {}
    },

    updateHeadSpringTypes (formData) {
      try {
        const { col } = this.ctx
        const oldGroupId = col.groupId
        const groupId = getGroupBySpringType(formData.springTypes[0].id)

        const colspan = formData.colspan.id
        const oldColspan = col.colspan

        if (oldGroupId !== groupId) {
          this.checkHasGroup(groupId)
          this.updateAfterChangeGroupId({ groupId, oldGroupId, colspan })
        } else if (oldColspan !== colspan) {
          if (colspan < oldColspan) {
            this.updateAfterDecrementColspan(groupId)
          } else {
            this.updateAfterIncrementColspan(groupId)
          }
        }

        col.value = [...formData.springTypes]
        col.colspan = formData.colspan.id

        this.closeModalSpringTypes()
      } catch (e) {}
    },
    updateAfterChangeGroupId ({ groupId, oldGroupId, colspan }) {
      let head
      if (this.isTableByGOST) {
        head = this.getHeadForGOST({ groupId, colspan })
      } else {
        head = this.getHeadForOperations({ groupId, colspan })
      }
      this.updateHead({
        groupId: oldGroupId,
        deleteCount: colspan,
        added: head
      })

      const columns = this.getColumnsForTiming({ groupId, colspan })
      this.updateColumns({
        groupId: oldGroupId,
        deleteCount: colspan,
        added: columns
      })
    },
    updateAfterDecrementColspan (groupId) {
      this.updateHead({ groupId, deleteCount: 1, addToIdx: 1 })
      this.updateColumns({ groupId, deleteCount: 1, addToIdx: 1 })
    },
    updateAfterIncrementColspan (groupId) {
      const head = []

      if (this.isTableByGOST) {
        head.push(this.getThGOST({ groupId }))
      } else {
        head.push(this.getThOperation({ groupId }))
      }

      this.updateHead({
        groupId,
        deleteCount: 0,
        added: head,
        addToIdx: 1
      })

      const columns = [this.getColumnTiming({ groupId, col: 2 })]
      this.updateColumns({
        groupId,
        deleteCount: 0,
        added: columns,
        addToIdx: 1
      })
    },

    checkHasGroup (groupId) {
      if (findByKeyValue(this.ctx.head[0], 'groupId', groupId)) {
        showAlertWarning('Такая группа по типу пружин уже есть в таблице')
        throw new Error()
      }
    },

    getHeadForGOST ({ groupId, colspan }) {
      const head = []

      for (let i = 0; i < colspan; i++) {
        head.push(this.getThGOST({ groupId }))
      }

      return head
    },
    getThGOST ({ groupId }) {
      return {
        text: '',
        value: [],
        groupId,
        tooltip: GOSTThTooltip,
        ...addCustomEdit(GOSTThEditKey),
        mapFn: mapGOSTTh,
        mapFnClasses: setBorderRightClassByGroupId
      }
    },

    getHeadForOperations ({ groupId, colspan }) {
      const head = []

      for (let i = 0; i < colspan; i++) {
        head.push(this.getThOperation({ groupId }))
      }

      return head
    },
    getThOperation ({ groupId }) {
      return {
        text: '',
        value: null,
        operation_id: null,
        groupId,
        ...addCustomEdit(operationsThEditKey),
        mapFn: getNameOrDash,
        mapFnClasses: setBorderRightClassByGroupId
      }
    },

    getColumnsForTiming ({ groupId, colspan }) {
      const columns = []

      for (let i = 0; i < colspan; i++) {
        columns.push(this.getColumnTiming({ groupId, col: i + 1 }))
      }

      return columns
    },
    getColumnTiming ({ groupId, col }) {
      return {
        label: '',
        key: `group-${groupId}-${col}`,
        groupId,
        ...addTimingColumnKeys(),
        mapFnClasses: this.isTableBySpringGroup ? setBorderRightClassByGroupId : null
      }
    },

    updateHead ({ groupId, added, deleteCount, addToIdx }) {
      this.spliceAndAddColumns({
        array: this.ctx.head[1],
        groupId,
        deleteCount,
        added,
        addToIdx
      })
    },
    updateColumns ({ groupId, added, deleteCount, addToIdx }) {
      this.spliceAndAddColumns({
        array: this.ctx.columns,
        groupId,
        deleteCount,
        added,
        addToIdx
      })
    },
    spliceAndAddColumns ({
      array,
      deleteCount = 2,
      groupId,
      added = [],
      addToIdx = 0
    }) {
      const idx = array.findIndex(item => item.groupId === groupId)
      array.splice(idx + addToIdx, deleteCount, ...added)
    },

    openModalCreateColumn (ctx) {
      this.setCtx(ctx)

      if (this.tableType === ETableTypes.byCoatingType) {
        return this.openModalCoatingTypeCreate()
      }

      this.openModalSpringTypesCreate()
    },

    switchModalCoatingTypeAction (formData) {
      if (this.modalCoatingType.isCreate) {
        return this.createHeadCoatingType(formData)
      }

      this.updateHeadCoatingType(formData)
    },
    createHeadCoatingType (formData) {
      try {
        const id = formData.coating.id

        this.checkHasCoatingType(id)

        this.ctx.head[0].push({
          value: { ...formData.coating },
          coating_id: id,
          isEdit: true,
          isCustomEdit: true,
          mapFn: getNameOrDash,
          hasButtonRemove: true
        })

        this.ctx.columns.push({
          key: `coating_${id}`,
          ...addTimingColumnKeys()
        })

        this.closeModalCoatingType()
      } catch (e) {}
    },
    updateHeadCoatingType (formData) {
      try {
        const id = formData.coating.id

        if (this.ctx.col.coating_id === id) {
          return this.closeModalCoatingType()
        }

        this.checkHasCoatingType(id)

        const { colIdx } = this.ctx
        this.ctx.head[0][colIdx].value = { ...formData.coating }
        this.ctx.head[0][colIdx].coating_id = id

        this.ctx.columns[colIdx - 2].key = `coating_${id}`

        this.closeModalCoatingType()
      } catch (e) {}
    },
    checkHasCoatingType (id) {
      if (findByKeyValue(this.ctx.head[0], 'coating_id', id)) {
        showAlertWarning('Такой тип покрытия уже есть в таблице')
        throw new Error()
      }
    },

    openModalCoatingTypeCreate () {
      this.modalCoatingType.isCreate = true
      this.openModalCoatingType()
    },
    openModalCoatingTypeUpdate () {
      this.modalCoatingType.formData.coating = this.ctx.col.value
      this.modalCoatingType.isCreate = false
      this.openModalCoatingType()
    },
    openModalCoatingType () {
      this.openModal(this.modalCoatingType.name)
    },
    closeModalCoatingType () {
      this.closeModalAndResetCtx()
      this.modalCoatingType.formData.coating = null
    },

    openModalSpringTypesCreate () {
      this.modalSpringTypes.isCreate = true
      this.openModalSpringTypes()
    },

    checkChangeTh (ctx) {
      this.setCtx(ctx)

      if (this.tableType === ETableTypes.byCoatingType) {
        return this.openModalCoatingTypeUpdate()
      }

      switch (ctx.col.editKey) {
        case GOSTThEditKey:
          return this.openModalGOST()
        case operationsThEditKey:
          return this.openModalOperation()
      }

      this.openModalSpringTypesUpdate()
    },

    openModalSpringTypesUpdate () {
      this.modalSpringTypes.formData.springTypes = [...this.ctx.col.value]
      this.modalSpringTypes.formData.colspan = findById(
        TABLE_COLSPAN,
        this.ctx.col.colspan
      )
      this.modalSpringTypes.isCreate = false
      this.openModalSpringTypes()
    },
    openModalSpringTypes () {
      this.openModal(this.modalSpringTypes.name)
    },
    closeModalSpringTypes () {
      this.closeModalAndResetCtx()
      this.modalSpringTypes.formData.springTypes = []
      this.modalSpringTypes.formData.colspan = TABLE_COLSPAN[1]
    },

    saveGOST (formData) {
      this.ctx.col.value = [...formData.gosts]
      this.closeModalGOST()
    },
    openModalGOST () {
      this.modalGOST.formData.gosts = [...this.ctx.col.value]
      this.openModal(this.modalGOST.name)
    },
    closeModalGOST () {
      this.closeModalAndResetCtx()
      this.modalGOST.formData.gosts = []
    },

    saveOperation (formData) {
      this.ctx.col.value = { ...formData.operation }
      this.ctx.col.operation_id = formData.operation.id
      this.closeModalOperation()
    },
    openModalOperation () {
      this.modalOperation.formData.operation = this.ctx.col.value
      this.openModal(this.modalOperation.name)
    },
    closeModalOperation () {
      this.closeModalAndResetCtx()
      this.modalOperation.formData.operation = null
    },

    openModalTiming (ctx) {
      this.setCtx(ctx)
      this.modalTiming.formData.timing = this.ctx?.value?.timing
      this.openModal(this.modalTiming.name)
    },
    saveTiming (formData) {
      const idx = this.ctx.dataRowIdx
      const key = this.ctx.col.key
      this.ctx.data.rows[idx][key] = {
        timing: formData?.timing,
        timing_id: formData?.timing?.id
      }

      this.closeModalTiming()
    },
    closeModalTiming () {
      this.closeModalAndResetCtx()
      this.modalTiming.formData.timing = null
    },

    closeModalAndResetCtx () {
      this.closeModal()
      this.resetCtx()
    },

    setCtx (ctx) {
      this.ctx = ctx
    },
    resetCtx () {
      this.ctx = null
    },

    removeColumn (ctx) {
      ctx.head[0].splice(ctx.colIdx, 1)

      if (this.tableType === ETableTypes.byCoatingType) {
        return this.removeColumnCoatingType(ctx)
      }

      this.removeColumnSpringTypes(ctx)
    },

    removeColumnCoatingType (ctx) {
      ctx.columns.splice(ctx.colIdx - 2, 1)
    },

    removeColumnSpringTypes (ctx) {
      const groupId = ctx.col.groupId

      ctx.head[1] = ctx.head[1].filter(th => th.groupId !== groupId)
      if (!ctx.head[1].length) {
        ctx.head.pop()
      }

      const idx = ctx.columns.findIndex(col => col.groupId === groupId)
      ctx.columns.splice(idx, 2)
    },

    getRowChild (columns) {
      const rowChild = {}

      for (const col of columns) {
        if (col.key === rangeCol.key) continue

        rowChild[col.key] = { timing: null, timing_id: null }
      }

      return rowChild
    }
  }
}
</script>

<style lang="scss">
.production-time-table {
  table {
    width: initial;
  }

  .custom-table__head tr:first-child .custom-table__th:nth-child(3) {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: var(--color__alabaster);
  }

  .custom-table__row:last-child {
    tr:first-child {
      td:first-child {
        border-bottom-left-radius: inherit;
      }
    }
  }

  .custom-table__td--1 {
    border-right: var(--border-bold) solid var(--color__cyan);
  }

  &--by-group {
    .custom-table__head {
      tr:first-child {
        .custom-table__th:not(:last-child) {
          border-right: var(--border-bold) solid var(--color__cyan);
        }
        .custom-table__th:nth-child(1),
        .custom-table__th:nth-child(2) {
          border-right: 1px solid var(--color__gray-blue);
          border-bottom: var(--border-bold) solid var(--color__cyan);
        }
        .custom-table__th:nth-child(3) {
          border-bottom: var(--border-bold) solid var(--color__cyan);
        }
      }
      tr:nth-child(2) {
        .custom-table__th {
          border-bottom: var(--border-bold) solid var(--color__cyan);
        }
      }
    }
  }

  &:not(.production-time-table--by-group) {
    .custom-table__th {
      border-bottom: var(--border-bold) solid var(--color__cyan);
    }
  }
}
</style>
