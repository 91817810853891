<template>
  <ul class='column'>
    <li
      v-for='(item, idx) in list'
      :key="idx"
    >
      {{ mapFn(item) }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ListSimple',
  props: {
    list: {
      type: Array,
      required: true
    },
    mapFn: {
      type: Function,
      default: (val) => val
    }
  }
}
</script>
