import store from '@/store'
import { ETableTypes, GOSTThTooltip, LOAD_CALENDAR_TABLE_OPERATIONS, rangeCol } from '../const'
import { mapGOSTTh, mapTimingTd } from '../utils'
import { findById } from '@/utils/array/find'
import { mapAmountRange, mapNameJoinSlash } from '@/shared/lib/utils/map/to-string'
import { getNameOrDash } from '@/shared/lib/utils/map/handbook'
import { setBorderRightClassByGroupId } from '@/shared/ui/CustomTable/utils'

export function mapTable (item) {
  switch (item.type_id) {
    case ETableTypes.currentLoad:
      return mapCurrentLoadTable(item)
    case ETableTypes.byOperation:
    case ETableTypes.byGOST:
    case ETableTypes.byCoatingType:
      return mapTimeTable(item)
  }

  return item
}

function mapCurrentLoadTable (item) {
  for (const row of item.table.rows) {
    setWindingMethods(row)
  }
  return item
}

function mapTimeTable (item) {
  const head = []

  if (item.type_id === ETableTypes.byCoatingType) {
    head.push(getMappedTimeTableCoatingType(item.table.head[0]))
  } else {
    head.push(getMappedTimeTableSpringType(item.table.head[0]))
  }

  if (item.table.head[1]) {
    if (item.type_id === ETableTypes.byOperation) {
      head.push(getMappedTimeTableOperation(item.table.head[1]))
    } else {
      head.push(getMappedTimeTableGOST(item.table.head[1]))
    }
  }

  mapColumns(item.table.columns)
  transformTimeTableRows(item.table.rows, item.table.columns)

  return {
    ...item,
    table: {
      ...item.table,
      head
    }
  }
}

function getMappedTimeTableCoatingType (head) {
  return head.map((item, idx) => {
    if (idx < 3) {
      return item
    }

    return {
      ...item,
      isEdit: store.getters['permission/isAdmin'],
      value: findById(store.state.records.properties.coating, item.coating_id),
      mapFn: getNameOrDash
    }
  })
}

function getMappedTimeTableSpringType (head) {
  return head.map((item, idx) => {
    if (idx < 3) {
      return item
    }

    const value = []

    for (const id of item.spring_type_ids) {
      const springType = findById(store.state.records.properties.product_type, id)
      value.push(springType)
    }

    return {
      ...item,
      isEdit: store.getters['permission/isAdmin'],
      value,
      mapFn: mapNameJoinSlash
    }
  })
}

function getMappedTimeTableOperation (head) {
  return head.map((item, idx) => ({
    ...item,
    isEdit: store.getters['permission/isAdmin'],
    value: findById(LOAD_CALENDAR_TABLE_OPERATIONS, item.operation_id),
    mapFn: getNameOrDash,
    mapFnClasses: setBorderRightClassByGroupId
  }))
}

function getMappedTimeTableGOST (head) {
  return head.map((item, idx) => {
    const value = []

    for (const id of item.gost_ids) {
      const gost = findById(store.state.handbook.gost.list, id)
      value.push(gost)
    }

    return {
      ...item,
      isEdit: store.getters['permission/isAdmin'],
      tooltip: GOSTThTooltip,
      value,
      mapFn: mapGOSTTh,
      mapFnClasses: setBorderRightClassByGroupId
    }
  })
}

function mapColumns (columns) {
  for (const col of columns) {
    if (col.key === rangeCol.key) {
      col.isEdit = store.getters['permission/isAdmin']
      col.mapFn = mapAmountRange
      continue
    }

    col.mapFn = mapTimingTd
    col.mapFnClasses = setBorderRightClassByGroupId
  }
}

function transformTimeTableRows (rows, columns) {
  for (const item of rows) {
    setWindingMethods(item)
    transformTimeTableRowsItem(item.rows, columns)
  }
}

function transformTimeTableRowsItem (rows, columns) {
  for (const item of rows) {
    for (const col of columns) {
      if (col.key === rangeCol.key) continue

      const id = item[col.key]?.timing_id
      item[col.key].timing = id ? findById(store.state.records.timingAll, id) : null
    }
  }
}

function setWindingMethods (item) {
  item.windingMethods = []

  for (const id of item.winding_method_ids) {
    const windingMethod = findById(store.state.records.properties.winding_method, id)
    item.windingMethods.push(windingMethod)
  }
}
