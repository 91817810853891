import { CUSTOM_TABLE_CLASSES } from './const'

export function addCustomEdit (editKey) {
  return {
    editKey,
    isEdit: true,
    isCustomEdit: true
  }
}

export function setBorderRightClassByGroupId (item, idx, array) {
  if (idx === array.length - 1) return ''
  if (item.groupId !== array[idx + 1].groupId) return CUSTOM_TABLE_CLASSES.borderRightBold
}
