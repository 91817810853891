<template>
  <ModalForm
    v-model='formData'
    title='Редактировать'
    @request="request"
    @reject="onClose"
  >
    <VInput
      class='mb-m'
      :label='inputLabel'
      name='value'
    />
  </ModalForm>
</template>

<script>
import VInput from '@/components/Form/Vinput/VInput'
import ModalForm from '@/components/ui/Modal/ModalForm.vue'

export default {
  name: 'ModalFormSimple',
  components: {
    ModalForm,
    VInput
  },
  props: {
    initData: {
      type: Object
    },
    inputLabel: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    formData: {
      value: ''
    }
  }),

  created () {
    this.syncFormData()
  },

  methods: {
    request (data) {
      this.$emit('request', data)
    },
    onClose () {
      this.$emit('close')
    },

    syncFormData () {
      this.formData.value = this.initData.value
    }
  }
}
</script>
